import * as React from "react";
import { useEffect } from "react";
import Head from "next/head";
import { AppProps } from "next/app";
import { ThemeProvider } from "@saleor/macaw-ui";
import { IntlProvider } from "react-intl";
import { useFormattedMessages } from "@/saleor-app-checkout/frontend/hooks/useFormattedMessages";
import AppContainer from "@/saleor-app-checkout/frontend/components/elements/AppContainer";
import AppProvider from "@/saleor-app-checkout/frontend/components/elements/AppProvider";
import PrivateSettingsProvider from "@/saleor-app-checkout/frontend/components/elements/PrivateSettingsProvider";
import "@saleor/checkout-storefront/dist/index.css";
import { RoutePropagator } from "@saleor/app-sdk/app-bridge/next";
import Script from "next/script";
import { SaleorAuthProvider, useAuthChange, useSaleorAuthClient } from "@saleor/auth-sdk/react";
import { Provider, cacheExchange, fetchExchange } from "urql";
import { useUrqlClient } from "@saleor/auth-sdk/react/urql";

declare global {
  // eslint-disable-next-line no-var -- var is required here
  var __SALEOR_CHECKOUT_ENV__: string;
}

const API_URI = process.env.NEXT_PUBLIC_SALEOR_API_URL || "";
const HOT_JAR_ID = process.env.NEXT_PUBLIC_HOT_JAR_ID || "";

export default function App(props: AppProps) {
  const { Component, pageProps } = props;

  const { locale, messages } = useFormattedMessages();

  const useSaleorAuthClientProps = useSaleorAuthClient({
    saleorApiUrl: API_URI,
    storage: typeof window !== "undefined" ? window.localStorage : undefined,
  });

  const { urqlClient, reset, refetch } = useUrqlClient({
    url: API_URI,
    fetch: useSaleorAuthClientProps.saleorAuthClient.fetchWithAuth,
    exchanges: [cacheExchange, fetchExchange],
  });

  useAuthChange({
    saleorApiUrl: API_URI,
    onSignedOut: () => reset(),
    onSignedIn: () => refetch(),
  });

  const version = [
    process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT || "(unknown_env)",
    process.env.NEXT_PUBLIC_GIT_BRANCH || "(unknown_branch)",
    process.env.NEXT_PUBLIC_SENTRY_RELEASE || "(unknown_release)",
  ].join("-");

  useEffect(() => {
    globalThis.__SALEOR_CHECKOUT_ENV__ = version;
  }, [version]);

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1, user-scalable=no" />
        <style>
          {`
            html,
            body {
              height: 100%;
              width: 100%;
            }
            *,
            *:after,
            *:before {
              box-sizing: border-box;
            }
            body {
              font-size: 1rem;
              margin: 0;
              background: transparent !important;
            }
          `}
        </style>
      </Head>
      <Script
        id="load-hotjar"
        strategy="lazyOnload"
        onLoad={() => {
          console.log("HotJar Loaded");
        }}
        onError={(error) => {
          console.log(error);
        }}
      >
        {`(function(h,o,t,j,a,r){
            h.hj = h.hj || function () {
              (h.hj.q = h.hj.q || []).push(arguments)
            };
            h._hjSettings={hjid:${HOT_JAR_ID},hjsv:6};
            a=o.getElementsByTagName('head')[0];
            r=o.createElement('script');r.async=1;
            r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
            a.appendChild(r);
          })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`}
      </Script>
      <AppProvider>
        <Provider value={urqlClient}>
          <SaleorAuthProvider {...useSaleorAuthClientProps}>
            <PrivateSettingsProvider>
              <IntlProvider
                locale={locale}
                messages={messages}
                onError={() => null} // Hide missing translation warnings
              >
                {/* @ts-expect-error React 17 <-> 18 types mismatch */}
                <ThemeProvider ssr={true}>
                  <RoutePropagator />
                  <AppContainer>
                    <Component {...pageProps} />
                  </AppContainer>
                  <footer
                    style={{
                      fontSize: "0.8em",
                      textAlign: "center",
                      color: "#777",
                      transform: "translateY(-100%)",
                      height: "1.7rem",
                      marginTop: "-1.7rem",
                    }}
                  >
                    <small>{version}</small>
                  </footer>
                </ThemeProvider>
              </IntlProvider>
            </PrivateSettingsProvider>
          </SaleorAuthProvider>
        </Provider>
      </AppProvider>
    </>
  );
}
